@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600;700&display=swap");

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.not-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body {
  margin: 0;
  font-family: "Rajdhani", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.paginator-action-btn {
  a {
    display: flex;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
.paginator-page-btn {
  a {
    width: 36px;
    height: 36px;
    border-color: transparent;
  }
}
